<template>
  <div>
    <a-modal
      width="1100px"
      :title="Title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="cancel"
    >
      <div style="height:600px;overflow:auto;padding:10px;">
        <a-form
          ref="formRef"
          :model="formState"
          :rules="rules"
        >
          <a-form-item
            label="培训名称："
            name="name"
          >
            <a-input
              allow-clear
              placeholder="例：202208培训"
              v-model:value="formState.name"
              style="width:40%"
            />
          </a-form-item>
          <div
            style="width:100%;border: 1px solid rgb(225, 225, 225);padding:10px;position:relative;margin-top:10px;"
            v-for="(item,index) in contentArray"
            :key="index"
          >
            <a-row>
              <a-col :span="22">
                <div>
                  <span
                    style="margin-left:10px;color: #60A2DE;cursor:pointer;"
                    @click="addFile(index)"
                  >+
                    添加绑定的培训文件</span>
                </div>
              </a-col>
              <a-col
                :span="2"
                v-if="contentArray.length>1"
              >
                <myIcon
                  name="delete"
                  style="cursor:pointer;font-size: 18px;"
                  @click="del(index)"
                />
              </a-col>
            </a-row>
            <a-table
              style="margin-top: 10px"
              :columns="columns1"
              :data-source="item.visitorTrainToMaterials"
              :pagination="false"
            >
              <template #operation="{ text, record,index2 }">
                <a-button
                  type="link"
                  style=" "
                  @click="delFile(index,index2)"
                >删除</a-button>
              </template>
            </a-table>
            <div style="margin-top:30px">
              <span
                style="margin-left:10px;color: #60A2DE;cursor:pointer;"
                @click="addTitle(index)"
              > + 添加绑定的题目</span>
            </div>
            <a-table
              style="margin-top: 10px"
              :columns="columns2"
              :data-source="item.visitorTrainToQuestions"
              :pagination="false"
            >
              <template #operation="{ text, record,index2 }">
                <a-button
                  type="link"
                  style=" "
                  @click="delTitle(index,index2)"
                >删除</a-button>
              </template>
            </a-table>
          </div>
          <div
            style="width:100%;text-align: center;cursor:pointer;"
            @click="add()"
          >
            <div style="width:200px;height:40px;margin-top:10px;display: inline-block; line-height: 40px; background: #FFFFFF;text-align: center;font-size:18px; border: 1px dashed #60A2DE;border-radius: 2px;">
              添加培训内容
            </div>
          </div>
          <div style="width:100%;border: 1px solid rgb(225, 225, 225);padding:10px;position:relative;margin-top:10px;">
            <a-row>
              <a-col
                :span="22"
                v-if="sourceData.length<1"
              >
                <div>
                  <span
                    style="margin-left:10px;color: #60A2DE;cursor:pointer;"
                    @click="addPaper(index)"
                  >+
                    添加绑定的考卷（仅可绑定一套试题）</span>
                </div>
              </a-col>
            </a-row>
            <a-table
              style="margin-top: 10px;margin-bottom: 10px"
              :columns="columns"
              :data-source="sourceData"
              :pagination="false"
            >
              <template #operation="{ text, record,index2 }">
                <a-button
                  type="link"
                  style=" "
                  @click="delPaper(index,index2)"
                >删除</a-button>
              </template>
            </a-table>
            <a-form-item
              v-if="sourceData.length>0"
              label="考试时长（Min)："
              name="duration"
            >
              <a-time-picker
                v-model:value="formState.duration"
                value-format="HH:mm:ss"
                :default-value="'00:01:00'"
              />
            </a-form-item>
            <a-form-item
              v-if="sourceData.length>0"
              label="及格分数："
              name="score"
            >
              <a-input-number
                allow-clear
                placeholder=""
                v-model:value="formState.score"
                style="width:30%"
              />
            </a-form-item>
          </div>
        </a-form>
      </div>
    </a-modal>
    <popupbindingFile
      ref="popupbindingFile"
      :checkIds="checkIds1"
    ></popupbindingFile>
    <popupbindingTitle
      ref="popupbindingTitle"
      :checkIds="checkIds2"
    ></popupbindingTitle>
    <popupbindingPaper
      ref="popupbindingPaper"
      :checkIds="checkIds"
    ></popupbindingPaper>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import myIcon from "@/components/icon/index";

import popupbindingFile from "./popupbindingFile.vue";
import popupbindingTitle from "./popupbindingTitle.vue";
import popupbindingPaper from "./popupbindingPaper.vue";
import { VisitorTrainController_post, VisitorTrainController_id, VisitorTrainController_put } from "@/api/api"
const columns = [{
  title: "序号",
  width: 60,
  dataIndex: "index",
  customRender: (row) => row.index + 1,
},
{
  title: "考卷名称 ",
  dataIndex: "title",
  width: 200,
  ellipsis: true,
},
{
  title: "考卷总分",
  dataIndex: "totalPoints",
},
{
  title: "考卷类型",
  dataIndex: "type",
  customRender: (text) => {
    return (text.text == 1 ? "选题组卷" : "随机组卷")
  },
},
{
  title: "考卷状态",
  dataIndex: "status",
  customRender: (text) => {
    return (text.text == 0 ? "未发布" : "已发布")
  },
},
{
  title: "操作",
  dataIndex: "operation",
  width: 200,
  slots: { customRender: "operation" },
},]
const columns1 = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row) => row.index + 1,
  },
  {
    title: "资料名称 ",
    dataIndex: "title",
    width: 200,
    ellipsis: true,
  },
  {
    title: "资料类别",
    dataIndex: "category",
  },
  {
    title: "文档类型",
    dataIndex: "type",
    customRender: (row) =>
      row.text === 1 ? "文档" : "视频",
  },
  {
    title: "资料状态",
    dataIndex: "status",
    customRender: (row) =>
      row.text === 0 ? "未发布" : "已发布",
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: 200,
    slots: { customRender: "operation" },
  },
];
const columns2 = [
  {
    title: "序号",
    width: 60,
    dataIndex: "index",
    customRender: (row) => row.index + 1,
  },
  {
    title: "试题描述 ",
    dataIndex: "title",
    width: 200,
    ellipsis: true,
  },
  {
    title: "问题类别",
    dataIndex: "category",
  },
  {
    title: "问题类型",
    customRender: (text) => {
      return (text.text == 1 ? "单选题" : (text.text == 2 ? "多选题" : "判断题"))
    },
    dataIndex: "type",
  },
  {
    title: "问题状态",
    dataIndex: "status",
    customRender: (text) => (text.text == 0 ? "未发布" : "已发布"),
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: 200,
    slots: { customRender: "operation" },
  },
];
export default defineComponent({
  name: "index",
  props: {
    FormData: {
      type: String,
      required: false,
    },
  },
  provide() {
    return {
      addTable: this.addTable,
    }
  },
  data() {
    return {
      edit: false,
      checkIds: [],
      checkIds1: [],
      checkIds2: [],
      Trainingcontent: [{
      }],
      Title: '',
      selectedRowKeys: [],
      columns,
      columns1,
      columns2,
      visible: false,
      confirmLoading: false,
      formState: {
        name: '',
        score: '',
        duration: '00:01:00'
      },
      sourceData: [],
      contentArray: [{
        visitorTrainToMaterials: [

        ],
        visitorTrainToQuestions: [

        ],
      }],
      rules: {
        name: [{ required: true, message: '不能为空', trigger: 'blur' }],
        score: [{
          required: true, message: '不能为空', trigger: 'change', transform: (value) => {
            if (value) {
              return value.toString()
            }
            return value
          }
        }],
        duration: [{
          required: true, message: '不能为空', trigger: 'change', transform: (value) => {
            if (value) {
              return value.toString()
            }
            return value
          }
        }],
      }
    };
  },
  created() { },
  components: {
    popupbindingFile,
    popupbindingTitle,
    popupbindingPaper,
    myIcon
  },
  methods: {
    add() {
      this.contentArray.push({
        visitorTrainToMaterials: [
        ],
        visitorTrainToQuestions: [
        ],
      })
    },
    del(index) {
      this.contentArray.splice(index, 1)
    },
    delFile(index1, index2) {
      this.contentArray[index1].visitorTrainToMaterials.splice(index2, 1)
    },
    delTitle(index1, index2) {
      this.contentArray[index1].visitorTrainToQuestions.splice(index2, 1)
    },
    delPaper(index) {
      this.sourceData.splice(index, 1)
      this.formState.score = '',
        this.formState.duration = '00:01:00'
    },
    addFile(index) {
      this.checkIds1 = this.contentArray[index].visitorTrainToMaterials.map(item => item.materialId)
      this.$refs.popupbindingFile.showModal(index, '绑定培训资料')
    },
    addTitle(index) {
      this.checkIds2 = this.contentArray[index].visitorTrainToQuestions.map(item => item.questionId)
      this.$refs.popupbindingTitle.showModal(index, '绑定培训题目')
    },
    addPaper(index) {
      this.checkIds = this.sourceData.map(item => item.examinationPaperId)
      this.$refs.popupbindingPaper.showModal(index, '绑定培训考卷')
    },
    cancel() {
      this.$refs.formRef.resetFields()
      this.sourceData = [],
        this.editor = false
      this.contentArray = [{
        visitorTrainToMaterials: [
        ],
        visitorTrainToQuestions: [
        ],
      }],
        this.visible = false;
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true;
        const params = {
          ...this.formState,
          examinationPaperId: this.sourceData[0] ? this.sourceData[0]?.examinationPaperId : '',
          contentArray: this.contentArray,
          type: 3,
          status: 0
        }
        if (!this.edit) {
          VisitorTrainController_post(params).then(res => {
            this.visible = false;
            this.confirmLoading = false;
            this.cancel()
            this.$parent.refresh();
          })
        } else {
          VisitorTrainController_put(params).then(res => {
            this.visible = false;
            this.confirmLoading = false;
            this.cancel()
            this.$parent.refresh();
          })
        }
      });
    },
    showModal(val, id) {
      this.Title = val
      this.visible = true
      this.edit = false
      this.formState.score = ''
      this.formState.duration = '00:01:00'
      if (id) {
        this.edit = true
        VisitorTrainController_id(id).then(res => {
          this.formState = {
            ...this.formState,
            ...res.data
          }
          this.contentArray = []
          res.data.contentArray.forEach((res, index) => {
            this.contentArray.push(res)
            this.contentArray[index].visitorTrainToMaterials = res.materials
            this.contentArray[index].visitorTrainToQuestions = res.questions
          })
          if (res.data.examinationPaper) {
            this.sourceData.push(res.data.examinationPaper)
          }
        })
      }
    },
    addTable(number, index, arr) {
      if (number == 1) {
        this.contentArray[index].visitorTrainToMaterials.push(...arr)
        this.checkIds1 = this.contentArray[index].visitorTrainToMaterials.map(item => item.materialId)
      }
      if (number == 2) {
        this.contentArray[index].visitorTrainToQuestions.push(...arr)
        this.checkIds2 = this.contentArray[index].visitorTrainToQuestions.map(item => item.questionId)
      }
      if (number == 3) {
        this.sourceData.push(...arr)
        this.checkIds = this.sourceData.map(item => item.examinationPaperId)
      }
    },
  },
});
</script>
<style lang="less">
</style>
    