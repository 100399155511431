import axios from "../axios-config";
/**
 * 分页查询类别
 */
 export function page(params: any) {
  return axios.get("/api/back/learn/CategoryController/page", {params:{
    ...params
  }});
}
/**
 * 查询所有类别
 */
 export function find(params: any) {
  return axios.get("/api/back/learn/CategoryController/find", {params:{
    ...params
  }});
}

//题库管理

/**
 * 新增试题
 */
 export function QuestionController_post(params: any) {
  return axios.post("/api/back/learn/QuestionController", {...params});
}
/**
 * 修改试题
 */
 export function QuestionController_put(params: any) {
  return axios.put("/api/back/learn/QuestionController", {...params});
}
/**
 * 删除试题
 */
 export function QuestionController_del(params: any) {
  return axios.delete("/api/back/learn/QuestionController",{params:{
    ...params
  }});
}
/**
 * 批量导入
 */
 export function QuestionController_importByExcel(data: any) {
  return axios.post("/api/back/learn/QuestionController/importByExcel", data);
}

/**
 * 批量修改试题发布状态
 */
 export function updateStatus_put(params: any) {
  return axios.put("/api/back/learn/QuestionController/updateStatus", {...params});
}

/**
 * 分页查询试题
 */
 export function QuestionControllerPage(params: any) {
  return axios.get("/api/back/learn/QuestionController/page", {params:{
    ...params
  }});
}
/**
 * 查询试题详情(返回答案)
 */
 export function QuestionController_id(id: string) {
  return axios.get(`/api/back/learn/QuestionController/${id}`);
}
/**
 * 不分页查询试题
 */
 export function QuestionController_find(params: any) {
  return axios.get('/api/back/learn/QuestionController/find', {params:{
    ...params
  }});
}

/**
 * 随机查询试题
 */
 export function randomFind_find(params: any) {
  return axios.get('/api/back/learn/QuestionController/randomFind', {params:{
    ...params
  }});
}

//考卷管理

/**
 * 新增考卷
 */
 export function ExaminationPaperController_post(params: any) {
  return axios.post("/api/back/learn/ExaminationPaperController", {...params});
}
/**
 * 修改考卷
 */
 export function ExaminationPaperController_put(params: any) {
  return axios.put("/api/back/learn/ExaminationPaperController", {...params});
}

/**
 * 删除考卷
 */
 export function ExaminationPaperController_del(params: any) {
  return axios.delete("/api/back/learn/ExaminationPaperController",{params:{
    ...params
  }});
}
/**
 * 批量修改考卷发布状态
 */
 export function ExaminationPaperControllerUpdateStatus_put(params: any) {
  return axios.put("/api/back/learn/ExaminationPaperController/updateStatus", {...params});
}

/**
 * 查询考卷详情
 */
 export function ExaminationPaperController_id(id: string) {
  return axios.get(`/api/back/learn/ExaminationPaperController/${id}`);
}

/**
 * 分页查询考卷
 */
 export function ExaminationPaperControllerPage(params: any) {
  return axios.get("/api/back/learn/ExaminationPaperController/page", {params:{
    ...params
  }});
}
/**
 * 不分页查询试题
 */
 export function ExaminationPaperController_find(params: any) {
  return axios.get('/api/back/learn/ExaminationPaperController/find', {params:{
    ...params
  }});
}

//访客培训管理

/**
 * 新增访客培训
 */
 export function VisitorTrainController_post(params: any) {
  return axios.post("/api/back/learn/VisitorTrainController", {...params});
}
/**
 * 修改访客培训
 */
 export function VisitorTrainController_put(params: any) {
  return axios.put("/api/back/learn/VisitorTrainController", {...params});
}

/**
 * 删除访客培训
 */
 export function VisitorTrainController_del(params: any) {
  return axios.delete("/api/back/learn/VisitorTrainController",{params:{
    ...params
  }});
}
/**
 * 修改访客培训状态
 */
 export function VisitorTrainControllerUpdateStatus(params: any) {
  return axios.put("/api/back/learn/VisitorTrainController/updateStatus", {...params});
}

/**
 * 查询访客培训详情
 */
 export function VisitorTrainController_id(id: string) {
  return axios.get(`/api/back/learn/VisitorTrainController/${id}`);
}
/**
 * 分页查询访客培训
 */
 export function VisitorTrainControllerPage(params: any) {
  return axios.get("/api/back/learn/VisitorTrainController/page", {params:{
    ...params
  }});
}
/**
 * 分页查询访客信息(日志)
 */
 export function VisitorControllerPage(params: any) {
  return axios.get("/api/back/learn/VisitorController/page", {params:{
    ...params
  }});
}

//培训计划管理

//
/**
 * 查询培训情况
 */
export function TrainingPlanToUserController_find(params: any) {
  return axios.get("/api/back/learn/TrainingPlanToUserController/find", {params:{
    ...params
  }});
}
/**
 * 分页查询培训情况(学习时长统计详情)
 */
export function TrainingPlanToUserControllerPage(params: any) {
  return axios.get("/api/back/learn/TrainingPlanToUserController/page", {params:{
    ...params
  }});
}

/**
 * 修改用户培训情况
 */
 export function TrainingPlanToUserController_updateStatus(params: any) {
  return axios.put("/api/back/learn/TrainingPlanToUserController/updateStatus", {...params});
}

/**
 * 新增培训计划
 */
 export function TrainingPlanController_post(params: any) {
  return axios.post("/api/back/learn/TrainingPlanController", {...params});
}
/**
 * 修改培训计划
 */
 export function TrainingPlanController_put(params: any) {
  return axios.put("/api/back/learn/TrainingPlanController", {...params});
}

/**
 * 删除培训计划
 */
 export function TrainingPlanController_del(params: any) {
  return axios.delete("/api/back/learn/TrainingPlanController",{params:{
    ...params
  }});
}
/**
 * 查询培训计划详情
 */
 export function TrainingPlanController_id(id: string) {
  return axios.get(`/api/back/learn/TrainingPlanController/${id}`);
}
/**
 * 分页查询培训计划
 */
 export function TrainingPlanControllerPage(params: any) {
  return axios.get("/api/back/learn/TrainingPlanController/page", {params:{
    ...params
  }});
}
/**
 * 查询培训总人数以及学时
 */
 export function TrainingPlanControllerStatisticsNum(params: any) {
  return axios.get("/api/back/learn/TrainingPlanController/statisticsNum", {params:{
    ...params
  }});
}

/**
 * 批量修改培训计划发布状态
 */
 export function TrainingPlanControllerUpdateStatus_put(params: any) {
  return axios.put("/api/back/learn/TrainingPlanController/updateStatus", {...params});
}
/**
 * 新增线下培训附件
 */
 export function TrainingPlanToFileController_post(params: any) {
  return axios.post("/api/back/learn/TrainingPlanToFileController", {...params});
}
/**
 * 删除培训附件
 */
 export function TrainingPlanToFileControllerr_del(params: any) {
  return axios.delete("/api/back/learn/TrainingPlanToFileController",{params:{
    ...params
  }});
}

/**
 * 分页查询培训计划
 */
 export function TrainingPlanToFileControllerPage(params: any) {
  return axios.get("/api/back/learn/TrainingPlanToFileController/page", {params:{
    ...params
  }});
}


//考试计划管理

/**
 * 新增考试计划
 */
 export function ExaminationPlanController_post(params: any) {
  return axios.post("/api/back/learn/ExaminationPlanController", {...params});
}
/**
 * 修改考试计划
 */
 export function ExaminationPlanController_put(params: any) {
  return axios.put("/api/back/learn/ExaminationPlanController", {...params});
}

/**
 * 删除考试计划
 */
 export function ExaminationPlanController_del(params: any) {
  return axios.delete("/api/back/learn/ExaminationPlanController",{params:{
    ...params
  }});
}
/**
 * 查询考试计划详情
 */
 export function ExaminationPlanController_id(id: string) {
  return axios.get(`/api/back/learn/ExaminationPlanController/${id}`);
}
/**
 * 分页查询考试计划
 */
 export function ExaminationPlanControllerPage(params: any) {
  return axios.get("/api/back/learn/ExaminationPlanController/page", {params:{
    ...params
  }});
}
/**
 * 批量修改考试计划发布状态
 */
 export function ExaminationPlanControllerUpdateStatus_put(params: any) {
  return axios.put("/api/back/learn/ExaminationPlanController/updateStatus", {...params});
}

//考试成绩管理

/**
 * 分页查询考试成绩管理
 */
 export function ExaminationPlanToUserControllerPage(params: any) {
  return axios.get("/api/back/learn/ExaminationPlanToUserController/page", {params:{
    ...params
  }});
}

/**
 * 查询考试成绩详情（个人）
 */
 export function ExaminationPlanToUserController_id(id: string) {
  return axios.get(`/api/back/learn/ExaminationPlanToUserController/${id}`);
}
/**
 * 查询考试成绩中答题记录详情（个人）
 */
 export function QuestionRecordControllerFindInfo(params: any) {
  return axios.get("/api/back/learn/QuestionRecordController/findInfo", {params:{
    ...params
  }});
}

//修改资料为3分类
// 资料分类维护-查询资料类型(题库，培训类别)

export function getCommodityList(params: any) {
  return axios.get("/api/back/learn/CategoryController/find", {params:{
    ...params
  }});
}
// 资料分类维护-新增资料类型
export function addCommodity(data: any) {
  return axios.post("/api/back/learn/CategoryController", data);
}

// 资料分类维护-修改资料类型
export function updateCommodity(data: any) {
  return axios.put("/api/back/learn/CategoryController", data);
}


// 资料分类维护-删除资料类型
export function deleteCommodity(params: any) {
  return axios.delete("/api/back/learn/CategoryController", {params:{
    ...params
  }});
}

//统计查询
//答题练习统计查询
export function QuestionRecordControllerStatistics(params: any) {
  return axios.get("/api/back/learn/QuestionRecordController/statistics", {params:{
    ...params
  }});
}
//考试成绩统计
export function ExaminationPlanToUserControllerStatistics(params: any) {
  return axios.get("/api/back/learn/ExaminationPlanToUserController/statistics", {params:{
    ...params
  }});
}
//时长统计
export function DurationStatisticsControllerPage(params: any) {
  return axios.get("/api/back/learn/TrainingPlanToAchievementController/pageStatistic", {params:{
    ...params
  }});
}
//生成pdf考卷
export function genPdf(params: any) {
  return axios.get("/api/back/learn/ExaminationPaperController/genPdf", {params:{
    ...params
  }});
}
