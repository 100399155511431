import axios from "@/axios-config";

/**
 * 分页查询资料
 * @param params 
 * @returns 
 */
export function getPage(params: any) {
    return axios.get('/api/back/learn/MaterialController/page', {
        params
    })
}

/**
 * 新增资料
 * @param params 
 * @returns 
 */
export function add(params: any) {
    return axios.post('/api/back/learn/MaterialController', params)
}

/**
 * 编辑资料
 * @param params 
 * @returns 
 */
export function update(params: any) {
    return axios.put('/api/back/learn/MaterialController', params)
}

/**
 * 删除资料
 * @param id 
 * @returns 
 */
export function del(id: any) {
    return axios.delete('/api/back/learn/MaterialController', {
        params: {
            id
        }
    })
}

/**
 * 批量修改状态
 * @param params 
 * @returns 
 */
 export function updateStatus(params: any) {
    return axios.put('/api/back/learn/MaterialController/updateStatus', params)
}

/**
 * 不分页查询资料
 */
 export function MaterialController_find(params: any) {
    return axios.get('/api/back/learn/MaterialController/find',{
        params
    })
  }

  //类型树
export function findByTree(params: any) {
    return axios.get("/api/back/learn/CategoryController/findByTree", {params:{
      ...params
    }});
  }
  